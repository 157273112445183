import React from "react"
import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"

import { wrapper } from "../utils/style"

import Layout from "../components/shared/layout"
import SEO from "../components/SEO/SEO"
import Breadcrumbs from "../components/shared/Breadcrumbs"

const Wrapper = styled("div")`
  ${wrapper}
  margin: 30px auto;
`

const List = styled("ul")`
  list-style: none;
  margin-top: 30px;
  padding: 0;
  li {
    padding: 7px 15px;
    margin-top: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  li:nth-of-type(2n) {
    background-color: transparent;
  }
  a {
    opacity: 0.8;
  }
  a:hover,
  a:active {
    text-decoration: none;
    opacity: 1;
  }
`

const HtmlSitemap = ({ data }) => {
  const Posts = data.allWordpressPost.edges.map(
    edge =>
      edge.node.acf &&
      edge.node.acf.post_template !== "service_post_carousel" && (
        <li key={edge.node.id} className="post">
          <Link
            to={"/" + edge.node.slug + "/"}
            dangerouslySetInnerHTML={{ __html: edge.node.title }}
          />
        </li>
      )
  )

  const Pages = data.allWordpressPage.edges.map(edge => (
    <li key={edge.node.id} className="page">
      {edge.node.slug === "home-page" ? (
        <Link to="/" dangerouslySetInnerHTML={{ __html: edge.node.title }} />
      ) : (
        <Link
          to={
            edge.node.wordpress_parent > 0
              ? edge.node.path
              : "/" + edge.node.slug + "/"
          }
        >
          {edge.node.title}
        </Link>
      )}
    </li>
  ))

  const Categories = data.allWordpressCategory.edges.map(edge => (
    <li key={edge.node.id} className="category">
      <Link
        to={edge.node.path.replace("category/", "")}
        dangerouslySetInnerHTML={{ __html: edge.node.name }}
      />
    </li>
  ))

  return (
    <Layout>
      <SEO
        title="HTML sitemap"
        description="HTML sitemap for holidaypalace: holidaypalace.net"
        pathname={"/sitemap/"}
      />
      <Wrapper>
        <Breadcrumbs
          page_title={"Sitemap"}
          breadcrumbs={[{ title: "Home", slug: "" }]}
        />

        <h1>HTML sitemap</h1>
        <List>
          {Pages}
          {Categories}
          {Posts}
        </List>
      </Wrapper>
    </Layout>
  )
}

export default HtmlSitemap

export const pageQuery = graphql`
  query all {
    allWordpressCategory(filter: { slug: { ne: "uncategorized" } }) {
      edges {
        node {
          id
          name
          slug
          path
          link
          wordpress_id
        }
      }
    }
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          id
          slug
          acf {
            post_template
          }
        }
      }
    }
    allWordpressPage(sort: { fields: [date] }) {
      edges {
        node {
          title
          id
          slug
          path
          wordpress_parent
        }
      }
    }
  }
`
